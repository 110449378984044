<app-loader
  [apiCall]="this.apiCall"
  [loader]="true"
  [loaderId]="'paymentAddModal'"
  style="min-height: 150px"
>

    <form
      [formGroup]="paymentForm"
      (ngSubmit)="onSubmit()"
      [hasUnsavedData]="paymentForm"
    >
      <button type="submit" hidden="hidden"></button>

      <div class="row mb-2">
        <div class="col-12 mr-auto"></div>
      </div>
      <div class="row">
        <div class="col-12 col-md">

          <ng-container *ngIf="!this.isReimbursement">
            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate
              >PAYMENT_LBL_FROM</label
              >
              <div class="col">
                <app-typeahead
                  [routeEndpoint]="'Accounts'"
                  [controlForm]="this.paymentForm.get('fromAccountId')"
                  [domain]="'contactId=' + this.contactId"
                  [orderBy]="'name asc'"
                  [limitToList]="true"
                  [readonly]="true"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate
              >PAYMENT_LBL_TO</label
              >
              <div class="col">
                <app-typeahead
                  [routeEndpoint]="'Accounts'"
                  [controlForm]="this.paymentForm.get('toAccountId')"
                  [domain]="'accountType=2,active=1'"
                  [orderBy]="'name asc'"
                  [limitToList]="true"
                />
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="this.isReimbursement">
            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate
              >PAYMENT_LBL_FROM</label
              >
              <div class="col">
                <app-typeahead
                  [routeEndpoint]="'Accounts'"
                  [controlForm]="this.paymentForm.get('fromAccountId')"
                  [domain]="'accountType=2,active=1'"
                  [orderBy]="'name asc'"
                  [limitToList]="true"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate
              >PAYMENT_LBL_TO</label
              >
              <div class="col">
                <app-typeahead
                  [routeEndpoint]="'Accounts'"
                  [controlForm]="this.paymentForm.get('toAccountId')"
                  [domain]="'contactId=' + this.contactId"
                  [orderBy]="'name asc'"
                  [limitToList]="true"
                  [readonly]="true"
                />
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col">
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
            >PAYMENT_LBL_MODE</label
            >
            <div class="col">
              <app-typeahead
                [limitToList]="true"
                [dataType]="DataType.Enum"
                [referenceEndpoint]="'Views/enum/values'"
                [referenceField]="'Comparanoo.Core.Enums.PaymentMode'"
                [valueMember]="'value'"
                [displayMember]="'name'"
                [dropdownStyle]="DropdownStyle.DropDownList"
                [controlForm]="this.paymentForm.get('paymentMode')"
                [readonly]="this.contactIsCompany"
              ></app-typeahead>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
            >PAYMENT_LBL_AMOUNT</label
            >
            <div class="col">
              <input
                class="form-control form-control-sm"
                type="text"
                formControlName="amount"
                checkFormFieldValidity
                [placeholder]="'€'"
                mask="separator.2"
                [suffix]="'PAYMENT_PLACEHOLDER_MONETARY' | translate"
                [showMaskTyped]="false"
                [decimalMarker]="','"
                [allowNegativeNumbers]="true"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
            >PAYMENT_LBL_DATE</label
            >
            <div class="col">
              <input
                class="form-control form-control-sm"
                type="date"
                formControlName="date"
                checkFormFieldValidity
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
            >PAYMENT_LBL_REFERENCE</label
            >
            <div class="col">
              <input
                class="form-control form-control-sm"
                type="text"
                [placeholder]="''"
                formControlName="reference"
                checkFormFieldValidity
              />
            </div>
          </div>
          <div class="form-group row" *ngIf="this.paymentForm.get('paymentMode')?.value != 'Cash'">
            <label class="col-4 col-form-label-sm" translate
            >PAYMENT_LBL_PROOF</label
            >
            <div class="col">
              <app-generic-file-input (fileEmitter)="onFileEmitterProof($event)"/>
            </div>
          </div>
        </div>
      </div>
    </form>
  <ng-container *ngIf="accountMovePaymentCreateInstance$ | async">
  </ng-container>
</app-loader>
