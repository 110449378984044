import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable, take } from 'rxjs';
import {
  removeValidators,
  setValidators,
} from 'src/app/shared/helper/formhelper';
import { ContractService } from 'src/app/shared/store/contract/contract.service';
import { ContractModel } from 'src/app/shared/store/contract/types';
import { Entity } from 'src/app/shared/store/view/types';

@UntilDestroy()
@Component({
  selector: 'app-contract-form-commission',
  templateUrl: './contract-form-commission.component.html',
  styleUrls: ['./contract-form-commission.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormCommissionComponent implements OnInit {
  form: FormGroup<any>;
  contract$: Observable<ContractModel>;
  isRateVisible: boolean;
  protected readonly Entity = Entity;

  constructor(
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
    private readonly contractService: ContractService
  ) {}

  ngOnInit(): void {
    this.contract$ = this.contractService.getByUniqueId.value$;
    this.isRateVisible = false;
    this.form = this.parent.form;
    this.form.addControl(
      'commissionFormGroup',
      this.formBuilder.group({
        sharedSalesPersonId: [],
        sharedCommissionRate: [0],
      })
    );

    this.contract$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.form.patchValue({
          commissionFormGroup: {
            sharedSalesPersonId: x.sharedSalesPersonId,
            sharedCommissionRate: x.sharedCommissionRate,
          },
        });
      });

    this.form.controls['commissionFormGroup']
      .get('sharedSalesPersonId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value == null) {
          this.isRateVisible = false;
        } else {
          this.isRateVisible = true;
        }
      });
  }
}
