import { CommissionPlanModel } from '../commission/types';

export const commissionPlanRuleFeatureKey = 'commissionPlanRule';

export class CommissionPlanRuleModel {
  id: number;
  name: string;
  sequence: number;
  insuranceCompanyId?: number;
  productCategoryId?: number;
  productLineId?: number;
  productLineName?: string;
  productId?: number;
  value: number;
  amountType: AmountType;
  commissionPlanRuleType: CommissionPlanRuleType;
  commissionPlanRuleSource: CommissionPlanRuleSource;
  commissionType: CommissionType;
  startDate: Date;
  endDate?: Date;
  commissionPlanIds?: number[];
  commissionPlans?: CommissionPlanModel[];
}

export type CommissionPlanRuleType =
  | 'CommissionRateFirstYear'
  | 'CommissionRateTerm';

export type AmountType = 'Percentage' | 'Monetary';

export type CommissionPlanRuleSource = 'RecurringFees' | 'ApplicationFees';
export type CommissionType = 'SalesPersonCommission' | 'CompanyCommission';
export type CommissionTypeSubject = CommissionType | '';
