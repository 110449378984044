import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserRoutingModule } from './user-routing.module';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UserDetailsComponent } from './user/user-details/user-details.component';
import { UserEditComponent } from './user/user-edit/user-edit.component';
import { UserTabsDetailsComponent } from './user/user-details/user-tabs-details/user-tabs-details.component';
import { UserDetailsExternalAccountComponent } from './user/user-details/user-details-tab/user-details-external-account/user-details-external-account.component';
import { UserExternalAccountCreateContentComponent } from './user/user-details/user-details-tab/user-details-external-account/user-external-account-create-content/user-external-account-create-content.component';
import { SalesTeamExtractNamePipe } from './user/user-details/sales-team-extract-name.pipe';

@NgModule({
  declarations: [
    UserCreateComponent,
    UserDetailsComponent,
    UserEditComponent,
    UserTabsDetailsComponent,
    UserDetailsExternalAccountComponent,
    UserExternalAccountCreateContentComponent,
    SalesTeamExtractNamePipe,
  ],
  imports: [CommonModule, SharedModule, UserRoutingModule],
})
export class UserModule {}
