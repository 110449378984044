import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DataType,
  DropdownStyle,
} from '../../../../shared/store/typeahead/types';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { datesValidator } from '../../../../shared/validators/dates-validator.validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { formatDate } from '../../../../shared/helper/datehelper';
import { ApplicationFeeRuleModel } from '../../../../shared/store/application-fee-rule/types';
import { ApplicationFeeRuleService } from '../../../../shared/store/application-fee-rule/application-fee-rule.service';

@UntilDestroy()
@Component({
  selector: 'app-application-fee-rule-form-content',
  templateUrl: './application-fee-rule-form-content.component.html',
  styleUrls: ['./application-fee-rule-form-content.component.scss'],
})
export class ApplicationFeeRuleFormContentComponent
  implements OnInit, OnDestroy
{
  view$: Observable<View>;
  applicationFeeRuleForm: FormGroup;

  applicationFeeRuleModel$: Observable<ApplicationFeeRuleModel>;
  createApplicationFeeRuleInstance$: Observable<ApplicationFeeRuleModel>;

  isEditMode: boolean = false;
  applicationFeeRuleId: string;

  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;

  constructor(
    private readonly applicationFeeRuleService: ApplicationFeeRuleService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.applicationFeeRuleModel$ =
      this.applicationFeeRuleService.getById.value$;
    this.createApplicationFeeRuleInstance$ =
      this.applicationFeeRuleService.createInstance.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.applicationFeeRuleForm = this.formBuilder.group(
      {
        name: [''],
        productLineId: [undefined, [Validators.required]],
        applicationFeeType: [undefined, [Validators.required]],
        minimumValue: [undefined, [Validators.required]],
        maximumValue: [undefined, [Validators.required]],
        defaultValue: [undefined, [Validators.required]],
        amountType: [undefined, [Validators.required]],
        startDate: [undefined, [Validators.required]],
        endDate: [undefined],
      },
      { validators: datesValidator('startDate', 'endDate') },
    );

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.applicationFeeRuleId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.applicationFeeRuleId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.applicationFeeRuleService.getById.call(this.applicationFeeRuleId);
    } else {
      this.applicationFeeRuleService.createInstance.call();
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'applicationFeeRuleCreate' || x == 'applicationFeeRuleUpdate')
          this.onSubmit();
        if (x == 'applicationFeeRuleList') {
          this.formService.clear();
          this.router.navigate(['Finance/ApplicationFeeRules/']);
        }
        if (x == 'applicationFeeRuleDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Finance/ApplicationFeeRules/Details',
            this.applicationFeeRuleId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set(
            '@applicationFeeRuleCreate',
            x.breadcrumbCreate,
          );
      });

    this.applicationFeeRuleModel$
      .pipe(
        filter((x) => this.isEditMode),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@applicationFeeRuleEdit', x.name);
        this.applicationFeeRuleForm.patchValue({
          name: x.name,
          productLineId: x.productLineId,
          applicationFeeType: x.applicationFeeType,
          minimumValue: x.minimumValue,
          maximumValue: x.maximumValue,
          defaultValue: x.defaultValue,
          amountType: x.amountType,
          startDate: formatDate(x.startDate, 'ToInverseSqlDate'),
          endDate: !!x.endDate
            ? formatDate(x.endDate, 'ToInverseSqlDate')
            : undefined,
        });
      });

    this.createApplicationFeeRuleInstance$
      .pipe(
        filter((x) => !this.isEditMode),
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.applicationFeeRuleForm?.patchValue({
          amountType: x.amountType,
          startDate: formatDate(x.startDate, 'ToInverseSqlDate'),
        });
      });

    //When the productLineId change, we call the existing application fee rules
    this.applicationFeeRuleForm
      .get('productLineId')
      ?.valueChanges?.pipe(distinctUntilChanged(), untilDestroyed(this))
      ?.subscribe((x) => {
        if (!!x) {
          this.applicationFeeRuleService.getByProductLineId.call(x.toString());
        } else {
          this.applicationFeeRuleService.getByProductLineId.reset();
        }
      });
  }

  onSubmit() {
    if (this.applicationFeeRuleForm.invalid) {
      this.applicationFeeRuleForm.markAllAsTouched();
      this.formService.setEntityErrors('APPLICATION_FEE_RULE');
      this.formService.countErrors(this.applicationFeeRuleForm, true);
      return;
    }

    const applicationFeeRuleModel: ApplicationFeeRuleModel = {
      id: this.applicationFeeRuleId ? +this.applicationFeeRuleId : 0,
      name: this.applicationFeeRuleForm.value.name!,
      minimumValue: this.applicationFeeRuleForm.value.minimumValue!,
      maximumValue: this.applicationFeeRuleForm.value.maximumValue!,
      defaultValue: this.applicationFeeRuleForm.value.defaultValue!,
      productLineId: this.applicationFeeRuleForm.value.productLineId!,
      amountType: this.applicationFeeRuleForm.value.amountType!,
      applicationFeeType: this.applicationFeeRuleForm.value.applicationFeeType!,
      startDate: this.applicationFeeRuleForm.value.startDate!,
      endDate:
        this.applicationFeeRuleForm.value.endDate! === ''
          ? undefined
          : this.applicationFeeRuleForm.value.endDate!,
    };

    console.log(applicationFeeRuleModel);

    let result$: Observable<ApplicationFeeRuleModel>;
    if (this.isEditMode) {
      result$ = this.applicationFeeRuleService.update.call(
        applicationFeeRuleModel,
      );
    } else {
      result$ = this.applicationFeeRuleService.add.call(
        applicationFeeRuleModel,
      );
    }

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        this.router.navigate([
          '/Finance/ApplicationFeeRules/Details',
          result.id,
        ]);
      });
  }

  ngOnDestroy(): void {
    this.applicationFeeRuleService.add.reset();
    this.applicationFeeRuleService.update.reset();
    this.applicationFeeRuleService.getByProductLineId.reset();

    if (this.isEditMode) {
      this.applicationFeeRuleService.getById.reset();
    } else {
      this.applicationFeeRuleService.createInstance.reset();
    }

    this.breadcrumbService.set('@applicationFeeRuleEdit', ' ');
    this.breadcrumbService.set('@applicationFeeRuleCreate', ' ');
  }
}
