<app-loader
  [apiCall]="this.apiCall"
  [loader]="true"
  [loaderId]="'accountMoveCreateModal'"
  style="min-height: 150px"
>
  <form
    [formGroup]="paymentForm"
    (ngSubmit)="onSubmit()"
    [hasUnsavedData]="paymentForm"
  >
    <button type="submit" hidden="hidden"></button>

    <div class="row mb-2">
      <div class="col-12 mr-auto"></div>
    </div>

    <div class="row">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_ACCOUNT_MOVE_TYPE</label
          >

          <div class="col">
            <app-typeahead
              [limitToList]="true"
              [dataType]="DataType.Enum"
              [referenceEndpoint]="'Views/enum/values'"
              [referenceField]="
                'Comparanoo.Core.Enums.Accounting.AccountMoveType'
              "
              [valueMember]="'name'"
              [displayMember]="'name'"
              [dropdownStyle]="DropdownStyle.DropDownList"
              [controlForm]="this.paymentForm.get('accountMoveName')"
            />
          </div>
        </div>

        <div class="form-group row" *ngIf="isClient">
          <label class="col-4 col-form-label-sm" translate
            >PAYMENT_LBL_TO</label
          >
          <div class="col">
            <app-typeahead
              [routeEndpoint]="'Accounts'"
              [controlForm]="this.paymentForm.get('creditAccountId')"
              [domain]="'accountType=2,active=1'"
              [orderBy]="'name asc'"
              [limitToList]="true"
            />
          </div>
        </div>
        <div class="form-group row" *ngIf="!isClient">
          <label class="col-4 col-form-label-sm" translate
            >PAYMENT_LBL_FROM</label
          >
          <div class="col">
            <app-typeahead
              [routeEndpoint]="'Accounts'"
              [controlForm]="this.paymentForm.get('debitAccountId')"
              [domain]="'accountType=2,active=1'"
              [orderBy]="'name asc'"
              [limitToList]="true"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_ACCOUNT_MOVE_NAME</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="text"
              formControlName="accountMoveLineName"
              checkFormFieldValidity
            />
          </div>
        </div>

        <div class="form-group row" *ngIf="this.isRecurringFees">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_ACCOUNTMOVE_PERIOD</label
          >
          <div class="col">
            <app-typeahead
              [controlForm]="this.paymentForm.controls['accountMovePeriod']"
              [dataValueMember]="'name'"
              [dropdownStyle]="DropdownStyle.DropDownList"
              [limitToList]="true"
              [data]="this.accountMovePeriodsModels$ | async"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >PAYMENT_LBL_AMOUNT</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="text"
              formControlName="unitPrice"
              checkFormFieldValidity
              [placeholder]="'€'"
              mask="separator.2"
              [suffix]="'PAYMENT_PLACEHOLDER_MONETARY' | translate"
              [showMaskTyped]="false"
              [decimalMarker]="','"
              [allowNegativeNumbers]="true"
            />
          </div>
        </div>

        <div class="form-group row" *ngIf="this.isRecurringFees">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_ACCOUNTMOVE_PERIOD_START_DATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="date"
              formControlName="periodStartDate"
              checkFormFieldValidity
            />
          </div>
        </div>

        <div class="form-group row" *ngIf="this.isRecurringFees">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_ACCOUNTMOVE_PERIOD_END_DATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              type="date"
              formControlName="periodEndDate"
              checkFormFieldValidity
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</app-loader>
