import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ErrorService } from '../../../error';
import { CommissionPlanRuleModel, CommissionType } from './types';
import { catchError } from 'rxjs/operators';
import { SalesTeamModel } from '../../sales-team/types';
import { InsuranceCompanyModel } from '../../insurance-company/types';
import { CrudType } from '../../../service/message/types';
import { MessageService } from '../../../service/message/message.service';

@Injectable({
  providedIn: 'root',
})
export class CommissionPlanRuleApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getById = (id: number): Observable<CommissionPlanRuleModel> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CommissionPlanRuleModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  getByProductLineId = (id: number): Observable<CommissionPlanRuleModel[]> => {
    let parameters: string[];
    parameters = ['productline', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CommissionPlanRuleModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  createInstance = (
    commissionType: CommissionType,
  ): Observable<CommissionPlanRuleModel> => {
    let parameters: string[];
    parameters = ['create'];
    let url = this.formatUrl(parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('commissionType', commissionType!);

    return this.httpClient
      .get<CommissionPlanRuleModel>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  add = (
    commissionPlanRuleModel: CommissionPlanRuleModel,
  ): Observable<CommissionPlanRuleModel> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<CommissionPlanRuleModel>(url, commissionPlanRuleModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  update = (
    commissionPlanRuleModel: CommissionPlanRuleModel,
  ): Observable<CommissionPlanRuleModel> => {
    let parameters: string[];
    parameters = [commissionPlanRuleModel.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<CommissionPlanRuleModel>(url, commissionPlanRuleModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  delete = (id: number): Observable<boolean> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .delete<boolean>(url, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.delete),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.commissionPlanRule.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
