import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../../common/store/generic.selectors';
import { CommissionModel, commissionFeatureKey } from './types';
import { ICommissionState } from './commission.reducer';

const commissionFeatureState =
  createFeatureSelector<ICommissionState>(commissionFeatureKey);

export const commissionSelectors = {
  getById: createApiCallSelectors<number, ICommissionState>(
    commissionFeatureState,
    'getById'
  ),
  getCommissionsPreview: createApiCallSelectors<void, ICommissionState>(
    commissionFeatureState,
    'getCommissionsPreview'
  ),
};
