import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  ApplicationFeeRuleAction,
  applicationFeeRuleActions,
} from './application-fee-rule.actions';
import { ApplicationFeeRuleApiService } from './application-fee-rule.api.service';

@Injectable()
export class ApplicationFeeRuleEffects {
  constructor(
    private readonly applicationFeeRuleApiService: ApplicationFeeRuleApiService,
    private readonly action$: Actions<ApplicationFeeRuleAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    applicationFeeRuleActions.getById,
    this.applicationFeeRuleApiService.getById,
  );

  getByProductLineId = createApiCallEffects(
    this,
    this.action$,
    applicationFeeRuleActions.getByProductLineId,
    this.applicationFeeRuleApiService.getByProductLineId,
  );
  createInstance = createApiCallEffects(
    this,
    this.action$,
    applicationFeeRuleActions.createInstance,
    this.applicationFeeRuleApiService.createInstance,
  );
  add = createApiCallEffects(
    this,
    this.action$,
    applicationFeeRuleActions.add,
    this.applicationFeeRuleApiService.add,
  );
  update = createApiCallEffects(
    this,
    this.action$,
    applicationFeeRuleActions.update,
    this.applicationFeeRuleApiService.update,
  );
  delete = createApiCallEffects(
    this,
    this.action$,
    applicationFeeRuleActions.delete,
    this.applicationFeeRuleApiService.delete,
  );
}
