import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommissionPlanRuleDetailsComponent } from './commission-plan-rule-details/commission-plan-rule-details.component';
import { SharedModule } from '../../../shared/shared.module';
import { CommissionPlanRuleCompanyModule } from './commission-plan-rule-company/commission-plan-rule-company.module';
import { CommissionPlanRuleSalesPersonModule } from './commission-plan-rule-sales-person/commission-plan-rule-sales-person.module';
import { CommissionPlanExtractNamePipe } from './commission-plan-rule-details/commission-plan-extract-name.pipe';

@NgModule({
  declarations: [
    CommissionPlanRuleDetailsComponent,
    CommissionPlanExtractNamePipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CommissionPlanRuleCompanyModule,
    CommissionPlanRuleSalesPersonModule,
  ],
})
export class CommissionPlanRuleModule {}
