import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { ApplicationFeeRuleModel } from './types';
import { applicationFeeRuleSelectors } from './application-fee-rule.selector';
import { applicationFeeRuleActions } from './application-fee-rule.actions';

@Injectable()
export class ApplicationFeeRuleService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<ApplicationFeeRuleModel, string> =
    this.genericApiCall(
      applicationFeeRuleActions.getById,
      applicationFeeRuleSelectors.getById,
      true,
    );

  getByProductLineId: GenericApiCall<ApplicationFeeRuleModel[], string> =
    this.genericApiCall(
      applicationFeeRuleActions.getByProductLineId,
      applicationFeeRuleSelectors.getByProductLineId,
      true,
    );
  createInstance: GenericApiCall<ApplicationFeeRuleModel, void> =
    this.genericApiCall(
      applicationFeeRuleActions.createInstance,
      applicationFeeRuleSelectors.createInstance,
      true,
    );
  add: GenericApiCall<ApplicationFeeRuleModel, ApplicationFeeRuleModel> =
    this.genericApiCall(
      applicationFeeRuleActions.add,
      applicationFeeRuleSelectors.add,
      true,
    );
  update: GenericApiCall<ApplicationFeeRuleModel, ApplicationFeeRuleModel> =
    this.genericApiCall(
      applicationFeeRuleActions.update,
      applicationFeeRuleSelectors.update,
      true,
    );
  delete: GenericApiCall<boolean, string> = this.genericApiCall(
    applicationFeeRuleActions.delete,
    applicationFeeRuleSelectors.delete,
    true,
  );
}
