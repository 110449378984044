<div class="form-group-title my-1" translate>
  PRODUCTLINE_FIELDSET_COMMISSION_RECURRING_FEES
</div>
<div class="row mt-2">
  <div class="col-12">
    <table class="table table-striped border-bottom">
      <thead>
      <tr>
        <th>
          <a translate>CONTRACT_TABLE_COMMISSION_PLAN_RULE_NAME</a>
        </th>
        <th>
          <a translate>CONTRACT_TABLE_COMMISSION_PLAN_RULE_VALUE</a>
        </th>
        <th>
          <a translate>CONTRACT_TABLE_COMMISSION_PLAN_RULE_START_DATE</a>
        </th>
        <th>
          <a translate>CONTRACT_TABLE_COMMISSION_PLAN_RULE_END_DATE</a>
        </th>
      </tr>
      </thead>

      <tbody>
      <ng-container *ngIf="this.commissionPlanRuleModels$ | async as commissionPlanRuleModels">
        <ng-container
          *ngFor="let commissionPlanRuleModel of commissionPlanRuleModels; trackBy: trackByCommissionPlanRuleId">
          <tr (click)="onClickLinkTo(commissionPlanRuleModel.id, 'Finance/CommissionPlanRuleCompanies/Details/')"
              class="row-action">
            <td>{{ commissionPlanRuleModel.name }}</td>
            <td>{{ commissionPlanRuleModel.value | showSignValueType: commissionPlanRuleModel.amountType }}</td>
            <td>
              {{
                !!commissionPlanRuleModel.startDate
                  ? (commissionPlanRuleModel.startDate | date : 'dd/MM/yyyy')
                  : '-'
              }}
            </td>
            <td>
              {{
                !!commissionPlanRuleModel.endDate
                  ? (commissionPlanRuleModel.endDate | date : 'dd/MM/yyyy')
                  : '-'
              }}
            </td>
          </tr>
        </ng-container>
      </ng-container>
      </tbody>
    </table>


  </div>
  <div class="col"></div>
</div>
