import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { applicationFeeRuleActions } from './application-fee-rule.actions';
import { ApplicationFeeRuleModel } from './types';

export interface IApplicationFeeRuleState
  extends IState<ApplicationFeeRuleModel> {
  getById: GenericState<ApplicationFeeRuleModel>;
  getByProductLineId: GenericState<ApplicationFeeRuleModel[]>;
  createInstance: GenericState<ApplicationFeeRuleModel>;
  add: GenericState<ApplicationFeeRuleModel>;
  update: GenericState<ApplicationFeeRuleModel>;
  delete: GenericState<boolean>;
}

export const applicationFeeRuleInitialState: IApplicationFeeRuleState = {
  getById: new GenericState<ApplicationFeeRuleModel>(),
  getByProductLineId: new GenericState<ApplicationFeeRuleModel[]>(),
  createInstance: new GenericState<ApplicationFeeRuleModel>(),
  add: new GenericState<ApplicationFeeRuleModel>(),
  update: new GenericState<ApplicationFeeRuleModel>(),
  delete: new GenericState<boolean>(),
};

const applicationFeeRuleReducers = [
  ...onApiCall<ApplicationFeeRuleModel>(
    applicationFeeRuleActions.getById,
    'getById',
  ),
  ...onApiCall<ApplicationFeeRuleModel[]>(
    applicationFeeRuleActions.getByProductLineId,
    'getByProductLineId',
  ),
  ...onApiCall<ApplicationFeeRuleModel>(
    applicationFeeRuleActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<ApplicationFeeRuleModel>(applicationFeeRuleActions.add, 'add'),
  ...onApiCall<ApplicationFeeRuleModel>(
    applicationFeeRuleActions.update,
    'update',
  ),
  ...onApiCall<boolean>(applicationFeeRuleActions.delete, 'delete'),
];

export const applicationFeeRuleReducer = createReducer(
  applicationFeeRuleInitialState,
  ...applicationFeeRuleReducers,
) as ActionReducer<IApplicationFeeRuleState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return applicationFeeRuleReducer(state, action);
}
