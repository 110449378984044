import { Injectable } from '@angular/core';
import { NotificationService } from '../../store/notification/notification.service';
import { HttpResponse } from '@angular/common/http';
import {
  NotificationStatus,
  NotificationType,
} from '../../store/notification/types';
import { OperatorFunction, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { CrudType } from './types';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private readonly notificationService: NotificationService) {}

  checkHttpStatus<T>(response: HttpResponse<T>, crudType: CrudType): T {
    if (response) {
      let content: string = '';

      switch (crudType) {
        case CrudType.create:
          content = 'NOTIFICATION_MSG_SUCCESS_CREATE';
          break;
        case CrudType.update:
          content = 'NOTIFICATION_MSG_SUCCESS_UPDATE';
          break;
        case CrudType.import:
          content = 'NOTIFICATION_MSG_SUCCESS_IMPORT';
          break;
        case CrudType.delete:
          content = 'NOTIFICATION_MSG_SUCCESS_DELETE';
          break;
        default:
          break;
      }

      switch (response.status) {
        case 200:
        case 201:
          this.notificationService.add({
            content: content,
            notificationStatus: NotificationStatus.Success,
            timeout: undefined,
            timestamp: new Date().getTime(),
            userCancelled: false,
            type: NotificationType.Toast,
            customNotification: false,
          });
          break;
        default:
          break;
      }

      return <T>response.body;
    }

    return response;
  }

  checkStatusCustomOperator<T>(
    crudType: CrudType,
  ): OperatorFunction<HttpResponse<T>, T> {
    return pipe(
      map((httpResponse) => {
        return this.checkHttpStatus<T>(httpResponse, crudType);
      }),
    );
  }
}
