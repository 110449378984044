export const commissionFeatureKey = 'commission';

export class CommissionModel {
  id: number;
  userId: number;
  contractId: number;
  amount: number;
  commissionPlanRuleId: number;
}

export class CommissionPlanModel {
  id: number;
  name: string;
}
export type CommissionPreviewModel = {
  id: number;
  contractContactName: string;
  name: string;
  accountMoveType: string;
  storeubscriptionDate: string;
  accountMoveStatus: string;
  companyCommissionAmount: number;
  salesPersonCommissionRate: number;
  shareCommissionRate: number;
  salesPersonCommissionAmount: number;
  salesPersonName: string;
};
