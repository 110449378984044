import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { ApplicationFeeRuleModel, applicationFeeRuleFeatureKey } from './types';
import { ICommissionPlanRuleState } from '../commissions/commission-plan-rule/commission-plan-rule.reducer';
import { IApplicationFeeRuleState } from './application-fee-rule.reducer';

const applicationFeeRuleFeatureState =
  createFeatureSelector<IApplicationFeeRuleState>(applicationFeeRuleFeatureKey);

export const applicationFeeRuleSelectors = {
  getById: createApiCallSelectors<number, IApplicationFeeRuleState>(
    applicationFeeRuleFeatureState,
    'getById',
  ),

  getByProductLineId: createApiCallSelectors<number, IApplicationFeeRuleState>(
    applicationFeeRuleFeatureState,
    'getByProductLineId',
  ),
  createInstance: createApiCallSelectors<void, IApplicationFeeRuleState>(
    applicationFeeRuleFeatureState,
    'createInstance',
  ),
  add: createApiCallSelectors<
    ApplicationFeeRuleModel,
    IApplicationFeeRuleState
  >(applicationFeeRuleFeatureState, 'add'),
  update: createApiCallSelectors<
    ApplicationFeeRuleModel,
    IApplicationFeeRuleState
  >(applicationFeeRuleFeatureState, 'update'),
  delete: createApiCallSelectors<boolean, IApplicationFeeRuleState>(
    applicationFeeRuleFeatureState,
    'delete',
  ),
};
