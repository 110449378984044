import { Pipe, PipeTransform } from '@angular/core';
import { AmountType } from '../../store/commissions/commission-plan-rule/types';

@Pipe({
  name: 'showSignValueType',
})
export class ShowSignValueTypePipe implements PipeTransform {
  transform(value: number, valueType: AmountType): string {
    let sign = '';
    if (valueType === 'Monetary') sign = '€';
    if (valueType === 'Percentage') sign = '%';

    return value.toFixed(2).toString() + ' ' + sign;
  }
}
