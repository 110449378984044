import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FinanceRoutingModule } from './finance-routing.module';
import { PaymentModeComponent } from './payment-mode/payment-mode.component';
import { SharedModule } from '../../shared/shared.module';
import { PaymentModule } from './payment/payment.module';
import { InvoiceModule } from './invoice/invoice.module';
import { CommissionPlanRuleModule } from './commission-plan-rule/commission-plan-rule.module';
import { CommissionModule } from './commission/commission.module';
import { ApplicationFeeRuleDetailsComponent } from './application-fee-rule/application-fee-rule-details/application-fee-rule-details.component';
import { ApplicationFeeRuleCreateComponent } from './application-fee-rule/application-fee-rule-create/application-fee-rule-create.component';
import { ApplicationFeeRuleEditComponent } from './application-fee-rule/application-fee-rule-edit/application-fee-rule-edit.component';
import { ApplicationFeeRuleFormContentComponent } from './application-fee-rule/application-fee-rule-form-content/application-fee-rule-form-content.component';
import { ApplicationFeeRuleTabsDetailsComponent } from './application-fee-rule/application-fee-rule-tabs-details/application-fee-rule-tabs-details.component';
import { ApplicationFeeRuleModule } from './application-fee-rule/application-fee-rule.module';

@NgModule({
  declarations: [
    PaymentModeComponent,
    ApplicationFeeRuleDetailsComponent,
    ApplicationFeeRuleCreateComponent,
    ApplicationFeeRuleEditComponent,
    ApplicationFeeRuleFormContentComponent,
    ApplicationFeeRuleTabsDetailsComponent,
  ],
  imports: [
    CommonModule,
    FinanceRoutingModule,
    SharedModule,
    PaymentModule,
    InvoiceModule,
    CommissionModule,
    CommissionPlanRuleModule,
    ApplicationFeeRuleModule,
  ],
})
export class FinanceModule {}
