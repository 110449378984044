<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  formGroupName="paymentSplittingFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group-title my-1" translate>
          CONTRACT_FIELDSET_PAYMENT_SPLITTING_PAYMENT_DETAILS
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_PAYMENT_SPLITTING_FORM_PAYMENT_TYPE</label
          >
          <div class="col">
            <input
              class="form-control-sm"
              #isAutomaticPayment
              type="checkbox"
              [checked]="true"
              (change)="onChangePaymentType(isAutomaticPayment.checked)"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_PAYMENT_SPLITTING_SPLITTING_MODE</label
          >
          <div class="col">
            <input type="hidden" formControlName="paymentSplittingId" />
            <select
              #myselect
              class="form-control form-control-sm"
              formControlName="paymentMode"
              checkFormFieldValidity
              (change)="onChangePaymentMode(myselect.value)"
            >
              <ng-container *ngIf="!isAutomaticPayment.checked">
                <option
                  *ngFor="let option of this.paymentSplittingNonDebit"
                  [value]="option.id"
                >
                  {{ option.name }}
                </option>
              </ng-container>
              <ng-container *ngIf="isAutomaticPayment.checked">
                <option
                  *ngFor="
                    let option of this.paymentSplittingDebit | groupBy : 'id'
                  "
                  [value]="option.key"
                >
                  {{ option.value[0].name }}
                </option>
              </ng-container>
            </select>
          </div>
        </div>

        <div class="form-group row" *ngIf="isAutomaticPayment.checked">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_PAYMENT_SPLITTING_DEBIT_DAY</label
          >
          <div class="col">
            <select
              #myselectDay
              class="form-control form-control-sm"
              formControlName="paymentDebitDay"
              checkFormFieldValidity
              (change)="onChangeDebitDay(myselectDay.value)"
            >
              <option
                *ngFor="let option of this.paymentSplittingDebitDayOfMonth"
                [value]="option.id"
                translate
                [translateParams]="{ dayOfMonth: option.name }"
                [selected]="this.paymentSplittingDebitDayOfMonth.length === 1"
              >
                CONTRACT_MSG_PAYMENT_SPLITTING_DEBIT_DAY
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col"></div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group-title my-1" translate>
          CONTRACT_FIELDSET_PAYMENT_SPLITTING_BANK_DETAILS
        </div>
        <app-bank-account-edit
          [form]="
            this.form.controls['paymentSplittingFormGroup'].get(
              'paymentBankAccount'
            )
          "
        />
      </div>
      <div class="col">
        <div
          [style.display]="
            isRefundingAccountSameAsPaymentAccount.checked ? 'none' : 'block'
          "
        >
          <div class="form-group-title my-1" translate>
            CONTRACT_FIELDSET_PAYMENT_SPLITTING_REFUNDING_DETAILS
          </div>
          <app-bank-account-edit
            [form]="
              this.form.controls['paymentSplittingFormGroup'].get(
                'refundingBankAccount'
              )
            "
          />
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_PAYMENT_SPLITTING_BANK_ACCOUNT_REFUNDING_SAME_AS_PAYMENT</label
          >
          <div class="col">
            <input
              class="form-control-sm"
              #isRefundingAccountSameAsPaymentAccount
              type="checkbox"
              [checked]="true"
              formControlName="sameBankAccount"
              (change)="
                onChangeSetRefundingValidators(
                  isRefundingAccountSameAsPaymentAccount.checked
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</div>
