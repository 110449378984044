import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatestWith, filter, Observable } from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { User } from 'src/app/shared/store/user/type';
import { UserService } from 'src/app/shared/store/user/user.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Entity, View } from '../../../../../shared/store/view/types';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { FormService } from '../../../../../shared/store/form/form.service';
import { DropdownStyle } from '../../../../../shared/store/typeahead/types';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit, OnDestroy {
  user$: Observable<User>;
  userForm: FormGroup;
  currentUser$: Observable<User>;

  view$: Observable<View>;

  imageUrl: string = '';
  file: File | undefined;

  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;

  constructor(
    private readonly userService: UserService,
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
  ) {
    this.user$ = this.userService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
    this.currentUser$ = this.userService.getCurrentUser.value$;
  }

  ngOnInit(): void {
    //Form declaration
    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      roles: [],
      active: [false],
      contactId: [undefined],
      contactPictureId: [null],
      contactPictureFile: [undefined],
      commissionPlanId: [undefined],
      salesTeamIds: [undefined],
      // name: new FormControl('',[validators.required, validators.maxLength(80)]),
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'userUpdate') this.onSubmit();
        if (x == 'userDetails') {
          this.formService.clear();
          this.router.navigate([
            'Configuration/Users/Details/',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.userService.getById.call(this.route.snapshot.params['id']);

    this.user$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.userForm.patchValue({
          firstName: x.contactFirstName,
          lastName: x.contactLastName,
          email: x.aspNetUserEmail,
          roles: x.roles,
          active: x.active,
          contactId: x.contactId,
          contactPictureId: x.contactPictureId,
          commissionPlanId: x.commissionPlanId,
          salesTeamIds: x.userSalesTeams.map((x) => x.id),
        });
        this.breadcrumbService.set('@userEdit', x.contactName);
        if (x.contactPicturePath) {
          this.imageUrl = environment.comparanooapiurl + x.contactPicturePath;
        }
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });
  }
  onSubmit() {
    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      this.formService.countErrors(this.userForm, true);
      return;
    }

    let user: User = {
      firstName: this.userForm.value.firstName!,
      lastName: this.userForm.value.lastName!,
      email: this.userForm.value.email!,
      roles: this.userForm.value.roles!,

      id: this.route.snapshot.params['id'],
      active: this.userForm.value.active!,
      contactId: this.userForm.value.contactId!,

      //avatar
      contactPictureId: this.userForm.value.contactPictureId!,
      contactPictureFile: this.file,

      //commission
      commissionPlanId: this.userForm.value.commissionPlanId!,

      //salesteams
      salesTeamIds: this.userForm.value.salesTeamIds!,

      //Others
      contactName: '',
      contactFirstName: '',
      contactLastName: '',
      contactPicturePath: '',
      aspNetUserEmail: '',
      aspNetUserUserName: '',
      aspNetUserAspNetRoles: [],
      rolesLoc: [],
      rolesModel: [],
      aspNetUserEmailConfirmed: false,
      userSalesTeams: [],
    };
    let result$ = this.userService.update.call(user);

    result$
      .pipe(
        combineLatestWith(this.currentUser$),
        filter(([result, currentUser]) => !!result && !!currentUser),
        take(1),
      )
      .subscribe(([result, currentUser]) => {
        this.formService.clear();

        if (result.id == currentUser.id) {
          this.userService.getCurrentUser.call();
        }

        this.router.navigate(['Configuration/Users/Details/', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.userService.update.reset();
    this.breadcrumbService.set('@userDetails', ' ');
  }

  onAvatarChange(file: File | undefined) {
    if (file) {
      this.file = file;
    } else {
      this.userForm.patchValue({
        pictureId: undefined,
      });
    }
  }
}
