import { createApiCallActions } from '../../../../common/store/generic.actions';
import { CommissionModel, CommissionPreviewModel } from './types';

export const commissionActions = {
  getById: createApiCallActions<CommissionModel>('commission', 'getById'),
  getCommissionsPreview: createApiCallActions<CommissionPreviewModel[]>(
    'commission',
    'getCommissionsPreview'
  ),
};

export type CommissionAction = typeof commissionActions;
