import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter } from 'rxjs';
import { BankAccountService } from 'src/app/shared/store/bank-account/bank-account.service';
import { BankAccountModel } from 'src/app/shared/store/bank-account/types';
import { ContractService } from 'src/app/shared/store/contract/contract.service';
import {
  ContractModel,
  HealthInsuranceContractDetailModel,
} from 'src/app/shared/store/contract/types';
import { PaymentSplittingService } from 'src/app/shared/store/payment-splitting/payment-splitting.service';
import { PaymentSplitting } from 'src/app/shared/store/payment-splitting/type';
import { BreadcrumbService } from 'xng-breadcrumb';
import { InsuranceCategory } from '../../../../shared/store/lead/types';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { SmartAction, View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';

@UntilDestroy()
@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
})
export class ContractDetailsComponent implements OnInit, OnDestroy {
  contractId: string;
  contractBase$: Observable<ContractModel>;
  contractHealthDetails$: Observable<HealthInsuranceContractDetailModel[]>;
  bankAccount$: Observable<BankAccountModel>;
  bankAccountRefund$: Observable<BankAccountModel>;
  paymentSplitting$: Observable<PaymentSplitting>;
  active = 1;
  view$: Observable<View>;

  smartActions: SmartAction[];

  constructor(
    private route: ActivatedRoute,
    private readonly contractService: ContractService,
    private readonly bankAccountService: BankAccountService,
    private readonly paymentSplittingService: PaymentSplittingService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly subHeaderService: SubHeaderService,
    private readonly modalService: NgbModal,
    private readonly router: Router
  ) {
    this.contractBase$ = this.contractService.getByUniqueId.value$;
    this.contractHealthDetails$ = this.contractService.getHealthDetail.value$;
    this.bankAccount$ = this.bankAccountService.getById.value$;
    this.paymentSplitting$ = this.paymentSplittingService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.contractId = this.route.snapshot.params['id'];
    this.contractService.getByUniqueId.call(this.contractId);

    this.smartActions = [
      {
        id: 'documents',
        label: 'SMARTACTIONS_LBL_DOCUMENTS',
        url: '/Documents/Documents/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'ContractId',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'Or',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'file-outline',
        actif: true,
      },
      {
        id: 'leads',
        label: 'SMARTACTIONS_LBL_LEAD',
        url: '/Crm/Leads/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'Id',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'Or',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'file-outline',
        actif: true,
      },
      // {
      //   id: 'payment',
      //   label: 'SMARTACTIONS_LBL_PAYMENTS',
      //   url: '/Crm/Leads/List',
      //   count: 1,
      //   filter: {
      //     domains: [
      //       {
      //         field: 'Id',
      //         operator: {
      //           id: 'EqualTo',
      //           label: '',
      //           numberOfValues: 1,
      //         },
      //         connector: 'Or',
      //         fieldLabel: '',
      //         text: '',
      //         isActive: true,
      //         text2: '',
      //         value: '',
      //         value2: null,
      //       },
      //     ],
      //     name: '',
      //     tag: 'SmartActionFilter',
      //     text: '',
      //   },
      //   icon: 'bank',
      //   actif: false,
      // },
      // {
      //   id: 'commissions',
      //   label: 'SMARTACTIONS_LBL_COMMISSIONS',
      //   url: '/Crm/Leads/List',
      //   count: 1,
      //   filter: {
      //     domains: [
      //       {
      //         field: 'Id',
      //         operator: {
      //           id: 'EqualTo',
      //           label: '',
      //           numberOfValues: 1,
      //         },
      //         connector: 'Or',
      //         fieldLabel: '',
      //         text: '',
      //         isActive: true,
      //         text2: '',
      //         value: '',
      //         value2: null,
      //       },
      //     ],
      //     name: '',
      //     tag: 'SmartActionFilter',
      //     text: '',
      //   },
      //   icon: 'file-outline',
      //   actif: false,
      // },
    ];

    this.contractBase$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contractDetails', x.contactName);
        switch (x.productCategoryProductCategoryEnumKey) {
          case InsuranceCategory.Health:
            this.contractService.getHealthDetail.call(x.id);
            break;
          case InsuranceCategory.Car:
            this.contractService.getCarDetail.call(x.id);
            break;
          case InsuranceCategory.House:
            this.contractService.getHouseDetail.call(x.id);
            break;
          default:
            break;
        }
        this.bankAccountService.getById.call(x.paymentBankAccountId.toString());
        this.paymentSplittingService.getById.call(
          x.paymentSplittingId.toString()
        );

        // SmartActions
        if (x.documents) {
          this.smartActions[0].filter.domains[0].value = x.id.toString();
          this.smartActions[0].filter.name = x.contactName;
          this.smartActions[0].filter.text = x.contactName;
          this.smartActions[0].count = x.documents.length;
          this.smartActions[0].label =
            x.documents.length > 1
              ? 'SMARTACTIONS_LBL_DOCUMENTS'
              : 'SMARTACTIONS_LBL_DOCUMENT';
        }
        if (x.leadId) {
          this.smartActions[1].filter.domains[0].value = x.leadId.toString();
          this.smartActions[1].filter.name = x.contactName;
          this.smartActions[1].filter.text = x.contactName;
          this.smartActions[1].count = 1;
        }
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'contractEdit') {
          this.router.navigate(['/Crm/ContractRequests/Edit', this.contractId]);
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  ngOnDestroy(): void {
    this.contractService.getByUniqueId.reset();
    this.contractService.getHealthDetail.reset();
    this.contractService.getCarDetail.reset();
    this.contractService.getHouseDetail.reset();
    // this.contractId = this.route.snapshot.params['id'];
    // this.contractBase$ = this.contractService.getByUniqueId.value$;
    // this.contractService.getByUniqueId.call(this.contractId);
    this.breadcrumbService.set('@contractDetails', ' ');
  }
}
