import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from '../../../shared/components/data-listing-provider/data-listing-provider.component';
import { AuthentificationGuard } from '../../../shared/guard/authentification-guard';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { Entity, HeaderMenuType } from '../../../shared/store/view/types';
import { ModuleId } from '../../../shared/store/navigation/types';
import { CommissionPlanRuleDetailsComponent } from '../commission-plan-rule/commission-plan-rule-details/commission-plan-rule-details.component';
import { CommissionPlanRuleCompanyCreateComponent } from '../commission-plan-rule/commission-plan-rule-company/commission-plan-rule-company-create/commission-plan-rule-company-create.component';
import { CommissionPlanRuleCompanyEditComponent } from '../commission-plan-rule/commission-plan-rule-company/commission-plan-rule-company-edit/commission-plan-rule-company-edit.component';
import { ApplicationFeeRuleDetailsComponent } from './application-fee-rule-details/application-fee-rule-details.component';
import { ApplicationFeeRuleEditComponent } from './application-fee-rule-edit/application-fee-rule-edit.component';
import { ApplicationFeeRuleCreateComponent } from './application-fee-rule-create/application-fee-rule-create.component';

const routes: Routes = [
  {
    path: 'Finance/ApplicationFeeRules',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.ApplicationFeeRule,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: ApplicationFeeRuleDetailsComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.ApplicationFeeRule,
          breadcrumb: { label: ' ', alias: 'applicationFeeRuleDetails' },
        },
      },
      {
        path: 'Create',
        component: ApplicationFeeRuleCreateComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.ApplicationFeeRule,
          breadcrumb: { label: ' ', alias: 'applicationFeeRuleCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: ApplicationFeeRuleEditComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.ApplicationFeeRule,
          breadcrumb: { label: ' ', alias: 'applicationFeeRuleEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ApplicationFeeRuleRoutingModule {}
