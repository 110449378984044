import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, Observable } from 'rxjs';
import { formatDate } from '../../../../../shared/helper/datehelper';
import { CommissionPlanRuleModel } from '../../../../../shared/store/commissions/commission-plan-rule/types';
import { take } from 'rxjs/operators';
import { CommissionPlanRuleService } from '../../../../../shared/store/commissions/commission-plan-rule/commission-plan-rule.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { FormService } from '../../../../../shared/store/form/form.service';
import { View } from '../../../../../shared/store/view/types';
import { datesValidator } from '../../../../../shared/validators/dates-validator.validators';

@UntilDestroy()
@Component({
  selector: 'app-commission-plan-rule-company-form-content',
  templateUrl: './commission-plan-rule-company-form-content.component.html',
  styleUrls: ['./commission-plan-rule-company-form-content.component.scss'],
})
export class CommissionPlanRuleCompanyFormContentComponent
  implements OnInit, OnDestroy
{
  view$: Observable<View>;
  commissionPlanRuleForm: FormGroup;

  commissionPlanRuleModel$: Observable<CommissionPlanRuleModel>;
  createCommissionPlanRuleInstance$: Observable<CommissionPlanRuleModel>;

  isEditMode: boolean = false;
  commissionPlanRuleId: string;

  constructor(
    private readonly commissionPlanRuleService: CommissionPlanRuleService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.commissionPlanRuleModel$ =
      this.commissionPlanRuleService.getById.value$;
    this.createCommissionPlanRuleInstance$ =
      this.commissionPlanRuleService.createInstance.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.commissionPlanRuleForm = this.formBuilder.group(
      {
        name: ['', [Validators.required]],
        productLineId: [undefined, [Validators.required]],
        value: [undefined, [Validators.required]],
        amountType: [undefined, [Validators.required]],
        startDate: [undefined, [Validators.required]],
        endDate: [undefined],
        commissionType: [undefined, [Validators.required]],
        commissionPlanRuleSource: [undefined, [Validators.required]],
        commissionPlanRuleType: [undefined, [Validators.required]],
        sequence: [0],
      },
      { validators: datesValidator('startDate', 'endDate') },
    );

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.commissionPlanRuleId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.commissionPlanRuleId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.commissionPlanRuleService.getById.call(this.commissionPlanRuleId);
    } else {
      this.commissionPlanRuleService.createInstance.call('CompanyCommission');
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (
          x == 'commissionPlanRuleCompanyCreate' ||
          x == 'commissionPlanRuleCompanyUpdate'
        )
          this.onSubmit();
        if (x == 'commissionPlanRuleCompanyList') {
          this.formService.clear();
          this.router.navigate(['Finance/CommissionPlanRuleCompanies/']);
        }
        if (x == 'commissionPlanRuleCompanyDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Finance/CommissionPlanRuleCompanies/Details',
            this.commissionPlanRuleId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set(
            '@commissionPlanRuleCompanyCreate',
            x.breadcrumbCreate,
          );
      });

    this.commissionPlanRuleModel$
      .pipe(
        filter((x) => this.isEditMode),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@commissionPlanRuleCompanyEdit', x.name);
        this.commissionPlanRuleForm.patchValue({
          name: x.name,
          productLineId: x.productLineId,
          value: x.value,
          amountType: x.amountType,
          startDate: formatDate(x.startDate, 'ToInverseSqlDate'),
          endDate: !!x.endDate
            ? formatDate(x.endDate, 'ToInverseSqlDate')
            : undefined,
          commissionType: x.commissionType,
          commissionPlanRuleSource: x.commissionPlanRuleSource,
          commissionPlanRuleType: x.commissionPlanRuleType,
          sequence: x.sequence,
        });
      });

    this.createCommissionPlanRuleInstance$
      .pipe(
        filter((x) => !this.isEditMode),
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.commissionPlanRuleForm?.patchValue({
          amountType: x.amountType,
          commissionType: x.commissionType,
          commissionPlanRuleSource: x.commissionPlanRuleSource,
          commissionPlanRuleType: x.commissionPlanRuleType,
          startDate: formatDate(x.startDate, 'ToInverseSqlDate'),
          sequence: x.sequence,
        });
      });

    //When the productLineId change, we call the existing commission plan rules
    this.commissionPlanRuleForm
      .get('productLineId')
      ?.valueChanges?.pipe(distinctUntilChanged(), untilDestroyed(this))
      ?.subscribe((x) => {
        if (!!x) {
          this.commissionPlanRuleService.getByProductLineId.call(x.toString());
        } else {
          this.commissionPlanRuleService.getByProductLineId.reset();
        }
      });
  }

  onSubmit() {
    if (this.commissionPlanRuleForm.invalid) {
      this.commissionPlanRuleForm.markAllAsTouched();
      this.formService.setEntityErrors('COMMISSION_PLAN_RULE');
      this.formService.countErrors(this.commissionPlanRuleForm, true);
      return;
    }

    const commissionPlanRuleModel: CommissionPlanRuleModel = {
      id: this.commissionPlanRuleId ? +this.commissionPlanRuleId : 0,
      name: this.commissionPlanRuleForm.value.name!,
      sequence: this.commissionPlanRuleForm.value.sequence!,
      value: this.commissionPlanRuleForm.value.value!,
      productLineId: this.commissionPlanRuleForm.value.productLineId!,
      amountType: this.commissionPlanRuleForm.value.amountType!,
      commissionPlanRuleType:
        this.commissionPlanRuleForm.value.commissionPlanRuleType!,
      commissionPlanRuleSource:
        this.commissionPlanRuleForm.value.commissionPlanRuleSource!,
      commissionType: this.commissionPlanRuleForm.value.commissionType!,
      startDate: this.commissionPlanRuleForm.value.startDate!,
      endDate:
        this.commissionPlanRuleForm.value.endDate! === ''
          ? undefined
          : this.commissionPlanRuleForm.value.endDate!,
    };

    let result$: Observable<CommissionPlanRuleModel>;
    if (this.isEditMode) {
      result$ = this.commissionPlanRuleService.update.call(
        commissionPlanRuleModel,
      );
    } else {
      result$ = this.commissionPlanRuleService.add.call(
        commissionPlanRuleModel,
      );
    }

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        this.router.navigate([
          '/Finance/CommissionPlanRuleCompanies/Details',
          result.id,
        ]);
      });
  }

  ngOnDestroy(): void {
    this.commissionPlanRuleService.add.reset();
    this.commissionPlanRuleService.update.reset();
    this.commissionPlanRuleService.getByProductLineId.reset();

    if (this.isEditMode) {
      this.commissionPlanRuleService.getById.reset();
    } else {
      this.commissionPlanRuleService.createInstance.reset();
    }

    this.breadcrumbService.set('@commissionPlanRuleCompanyEdit', ' ');
    this.breadcrumbService.set('@commissionPlanRuleCompanyCreate', ' ');
  }
}
