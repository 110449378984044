import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../../common/store/generic.effects';
import {
  CommissionPlanRuleAction,
  commissionPlanRuleActions,
} from './commission-plan-rule.actions';
import { CommissionPlanRuleApiService } from './commission-plan-rule.api.service';

@Injectable()
export class CommissionPlanRuleEffects {
  constructor(
    private readonly commissionPlanRuleApiService: CommissionPlanRuleApiService,
    private readonly action$: Actions<CommissionPlanRuleAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    commissionPlanRuleActions.getById,
    this.commissionPlanRuleApiService.getById,
  );
  getByProductLineId = createApiCallEffects(
    this,
    this.action$,
    commissionPlanRuleActions.getByProductLineId,
    this.commissionPlanRuleApiService.getByProductLineId,
  );
  createInstance = createApiCallEffects(
    this,
    this.action$,
    commissionPlanRuleActions.createInstance,
    this.commissionPlanRuleApiService.createInstance,
  );
  add = createApiCallEffects(
    this,
    this.action$,
    commissionPlanRuleActions.add,
    this.commissionPlanRuleApiService.add,
  );
  update = createApiCallEffects(
    this,
    this.action$,
    commissionPlanRuleActions.update,
    this.commissionPlanRuleApiService.update,
  );
  delete = createApiCallEffects(
    this,
    this.action$,
    commissionPlanRuleActions.delete,
    this.commissionPlanRuleApiService.delete,
  );
}
