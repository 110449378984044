import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthentificationGuard } from '../../shared/guard/authentification-guard';
import { HasUnsavedChangesGuard } from '../../shared/guard/has-form-changed.guard';
import { Entity, HeaderMenuType } from '../../shared/store/view/types';
import { ModuleId } from '../../shared/store/navigation/types';
import { PaymentModeComponent } from './payment-mode/payment-mode.component';

const routes: Routes = [
  {
    path: 'Finance',
    redirectTo: 'Finance/Commissions/Previews/List',
    pathMatch: 'full',
  },
  // {
  //   path: 'Finance/Payments',
  //   data: {
  //     breadcrumb: { label: ' ', alias: 'paymentList' },
  //   },
  //   children: [
  //     {
  //       path: '',
  //       redirectTo: 'List',
  //       pathMatch: 'full',
  //     },
  //     {
  //       path: 'List',
  //       component: PaymentComponent,
  //       canActivate: [AuthentificationGuard],
  //       canDeactivate: [HasUnsavedChangesGuard],
  //       data: {
  //         headerMenu: HeaderMenuType.Finance,
  //         moduleId: ModuleId.finance,
  //         entity: Entity.Payment,
  //         breadcrumb: { skip: true },
  //       },
  //     },
  //   ],
  // },
  {
    path: 'Finance/PaymentModes',
    data: {
      breadcrumb: { label: ' ', alias: 'paymentModeList' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: PaymentModeComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.PaymentMode,
          breadcrumb: { skip: true },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FinanceRoutingModule {}
